import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'

import BG from '../../Components/images/reiki.jpg'

const Reiki = () => {
  const lang = useContext(LanguageContext).language
  const text = {
    traitement1: {
      fr: "&nbsp;Reiki",
      nl: "&nbsp;Reiki"
    },
    traitement2: {
      fr: "Le Reiki est une science de guérison très ancienne par imposition des mains, redécouverte au milieu du 19ème siècle au Japon par le Dr. Mikao Usui.",
      nl: "Reiki is een eeuwenoude genezingstechniek door handoplegging. De techniek werd halverwege de 19de eeuw in Japan herontdekt door dr Mikao Usui."
    },
    traitement3: {
      fr: "C'est une méthode de soin à la fois simple, directe et puissante, qui utilise l'énergie universelle de vie pour lever les blocages, restaurer le flot de l'énergie dans nos différents corps énergétiques et stimuler l’auto-guérison et le bien-être.",
      nl: "Het is een eenvoudige, directe en krachtige behandelingsmethode, die gebruik maakt van de universele levensenergie: blokkages worden losgemaakt, de energiestroom in onze verschillende energetische lichamen wordt hersteld en het welzijn wordt bevorderd."
    },
    traitement5: {
      fr: "Voir Formations",
      nl: ""
    },
  }

  return (
    <div style={styles.container}>
      <PhotoBox Foto={BG} backgroundPosition='top left'>
        <LightBox variant='primary' align='flex-start'>
          <h1 dangerouslySetInnerHTML={{__html: text.traitement1[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start'>
          <div dangerouslySetInnerHTML={{__html: text.traitement2[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end'>
          <div dangerouslySetInnerHTML={{__html: text.traitement3[lang]}}/>
        </LightBox>
      </PhotoBox>
    </div>
  )
}
export default Reiki


//////////////////////

const styles = {
  container: {
    display: 'flex',
  }
}

//////////////////////////////////
