import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import laurence3 from '../../Components/images/laurence3-min.jpg'
import Zen1 from '../../Components/images/zen1.jpg'
import zonnebloem1 from '../../Components/images/zonnebloem1.jpg'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'
import ColorBox from '../../Components/ColorBox'

const Content = () => {

  const lang = useContext(LanguageContext).language
  const text = {
    qui1: {
      fr: "INFIRMIERE ET PRATICIENNE REIKI ",
      nl: "VERPLEEGKUNDIGE EN REIKI THERAPEUT"
    },
    qui2: {
      fr: "Infirmière suisse, diplômée en soins généraux, j’ai voyagé, vécu et travaillé pendant 14 ans dans différents pays pour des organisations humanitaires. J’ai beaucoup appris des différentes cultures et personnes rencontrées sur place et lors de mes voyages, tant au niveau des manières d’appréhender la vie qu’au niveau des croyances.",
      nl: "Als algemeen verpleegkundige heb ik gedurende 14 jaar gewerkt voor humanitaire organisaties in diverse landen. Tijdens deze periode heb ik veel geleerd van de mensen die ik ontmoette en de culturen waarin ik leefde."
    },
    qui3: {
      fr: "Depuis toujours, je m’intéresse à une approche de santé globale et, plus récemment, à une approche active de la personne vis-à-vis de sa propre santé.",
      nl: "Al lang hecht ik veel belang aan een actieve benadering van de mens in zijn eigen gezondheid."
    },
    qui4: {
      fr: "Depuis 2007, je me forme à diverses thérapies alternatives intégrant la personne globalement au niveau physique, psychique, émotionnel et spirituel. ",
      nl: "Sinds 2007 heb ik me bekwaamd in diverse therapeutische methodes die de persoon op fysisch, emotioneel en spiritueel vlak als geheel beschouwen."
    },
    qui5: {
      fr: "En juin 2014, j’ai ouvert mon cabinet à Anvers où je pratique le Reiki et divers soins énergétiques et massages. De 2015 à 2021, j'ai fais partie d’un centre à Bruxelles (CentrEmergences à Etterbeek) qui regroupait différents thérapeutes, psychologues et autres professionnel/les de la santé.",
      nl: "Vanaf juni 2014 heb ik mijn eigen praktijk in Antwerpen, waar ik Reiki en verschillende andere energetische massages en verzorgingen aanbied. Van 2015 tot 2021 was ik verbonden aan een groepspraktijk in Brussel (CentrEmergences in Etterbeek) met verschillende therapeuten en psychologen."
    },
    qui6: {
      fr: "Apprendre à me découvrir en tant qu’être humain, dans tous mes aspects, étant primordial pour moi, je suis en permanence à l’écoute des messages de mon âme et en formation continue pour acquérir de nouveaux outils en tant que thérapeute.",
      nl: "Mezelf leren kennen als mens in al mijn aspecten is essentieel voor mij. Ik luister voordurend naar de behoeften van mijn ziel en probeer steeds te groeien als therapeut."
    },
    formation1: {
      fr: "FORMATIONS « CLASSIQUES »",
      nl: "OPLEIDING"
    },
    formation2: {
      fr: "INFIRMIERE EN SOINS GENERAUX<br/> – La Source, Lausanne, Suisse /  1989 à 1993.",
      nl: "DIPLOMA ALGEMENE VERPLEEGKUNDE<br/> – La Source, Lausanne, Zwitserland / 1989 - 1993."
    },
    formation3: {
      fr: "MEDECINE TROPICALE<br/> – Institut de Médecine Tropicale Prince Léopold (IMT), Anvers, Belgique / 1994 à 1995.",
      nl: "DIPLOMA TROPISCHE GENEESKUNDE<br/> – Instituut voor Tropische Geneeskunde (ITG), Antwerpen, Belgïe / 1994 - 1995."
    },
    formation4: {
      fr: "HEALTH EMERGENCY IN LARGE POPULATION (HELP)<br/> – Université de Santé Publique, Pretoria, Afrique du Sud / 2000.",
      nl: "CERTIFICAAT HEALTH EMERGENCY IN LARGE POPULATION (HELP)<br/> – University of Public Health, Pretoria, Zuid Afrika / 2000."
    },
    formation5: {
      fr: "SOINS PALLIATIFS<br/> – Fondation Rive Neuve, Villeneuve, Suisse / 2007.",
      nl: "CERTIFICAAT PALLIATIEVE ZORGEN<br/> - Fondation Rive Neuve, Villeneuve, Zwitserland / 2007."
    },
    formation6: {
      fr: "CONNAISSANCES DE BASE EN PSYCHOLOGIE<br/> – Centre National d’Etudes à Distance (CNED), Brunoy, France / 2007 à 2010.",
      nl: "CERTIFICAAT BASISKENNIS PSYCHOLOGIE<br/> – Centre National d’Etudes à Distance (CNED), Brunoy, Frankrijk / 2007 - 2010."
    },
    formation7: {
      fr: "FORMATIONS PRATICIENNE/THERAPEUTE REIKI ET SOINS ENERGETIQUES",
      nl: "OPLEIDING ALS REIKI THERAPEUT EN ENERGETISCHE BEHANDELINGEN"
    },
    formation8: {
      fr: "REIKI : Initiations 1, 2 & 3 (thérapeute)<br/>- Daniella Monnet, maître Reiki & thérapeute - Vevey, Suisse / 2007 – 2008 – 2011 – 2012.<br/> Depuis août 2017, en formation pour devenir maître/ enseignante Reiki.",
      nl: "REIKI : Initiatie 1, 2 & 3 (therapeut)<br/>- Daniella Monnet, Reikimeester & therapeut - Vevey, Zwistserland / 2007 – 2008 – 2011 – 2012.<br/>Sinds augustus 2017, opleiding tot Reikimeester."
    },
    formation9: {
      fr: "HARMONISATION ENERGETIQUE<br/>- Daniella Monnet, maître Reiki & thérapeute - Vevey, Suisse / 2010.",
      nl: "ENERGETISCHE HARMONISATIE<br/>- Daniella Monnet, Reikimeester & therapeut - Vevey, Zwitserland / 2010."
    },
    formation10: {
      fr: "MASSAGE AURIQUE & SOLIDES DE PLATON<br/> - Daniella Monnet, maître Reiki & thérapeute - Vevey, Suisse / 2011.",
      nl: "AURAMASSAGE &amp; PLATO’S REGELMATIGE VEELVLAKKEN<br/>- Daniella Monnet, Reikimeester & therapeut - Vevey, Zwitserland / 2011."
    },
    formation11: {
      fr: "LES MEMOIRES DE L’AME DANS LES CELLULES HUMAINES : niveaux 1& 2<br/> - Céline Bouffard Brûlé, thérapeute - Esmonts, Suisse / 2010 – 2011 – 2012 – 2013.",
      nl: "DE HERINNERINGEN VAN DE ZIEL IN DE MENSELIJKE CELLEN : niveau 1 & 2<br/>- Céline Bouffard Brûlé, therapeut - Esmonts, Zwitserland / 2010 – 2011 – 2012 – 2013."
    },
    formation12: {
      fr: "MASSAGE ETRIQUE<br/> –Céline Bouffard Brûlé, thérapeute - Guebwiller, Alsace, France / 2012.",
      nl: "WEZENSMASSAGE<br/> –Céline Bouffard Brûlé, therapeut - Guebwiller, Alsace, Frankrijk / 2012."
    },
    formation13: {
      fr: "THERAPIE DORSALE SELON DORN & BREUSS : niveaux 1 & 2<br /> - Pascal Cavin, thérapeute - Esmonts, Suisse / 2012.",
      nl: "DORN- & BREUSS-MASSAGE : niveau 1 & 2 - Pascal Cavin, therapeut - Esmonts, Zwitserland  / 2012."
    },
  }

  return (
    <div style={styles.root}>
      <ColorBox background='background2'>
        <LightBox variant='primary' align='flex-end'>
          <h1 >Laurence TSCHANZ</h1>
        </LightBox>
        <LightBox variant='white'>
          <h4 dangerouslySetInnerHTML={{__html: text.qui1[lang]}}/>
        </LightBox>
        <img src={laurence3} alt='' style={{ maxWidth:'100%' }}/>
      </ColorBox>

      <PhotoBox Foto={Zen1}>
        <LightBox variant='white' align='flex-start' maxWidth='420px'>
          <div dangerouslySetInnerHTML={{__html: text.qui2[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start' maxWidth='420px'>
          <div dangerouslySetInnerHTML={{__html: text.qui3[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start' maxWidth='320px'>
          <div dangerouslySetInnerHTML={{__html: text.qui4[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start' maxWidth='300px'>
          <div dangerouslySetInnerHTML={{__html: text.qui5[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start' maxWidth='260px'>
          <div dangerouslySetInnerHTML={{__html: text.qui6[lang]}}/>
        </LightBox>
        <div style={{ paddingBottom: '50px'}} />
      </PhotoBox>

      <PhotoBox Foto={zonnebloem1} backgroundPosition='bottom center'>
        <LightBox variant='dark' align='flex-start'>
          <h3 dangerouslySetInnerHTML={{__html: text.formation1[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end' maxWidth='600px'>
          <ul>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation2[lang]}}/>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation3[lang]}}/>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation4[lang]}}/>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation5[lang]}}/>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation6[lang]}}/>
          </ul>
        </LightBox>
        <div style={{ paddingBottom: '50px'}} />

        <LightBox variant='dark' align='flex-start' maxWidth='600px'>
          < h3 dangerouslySetInnerHTML={{__html: text.formation7[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end' maxWidth='600px'>
          <ul>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation8[lang]}}/>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation9[lang]}}/>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation10[lang]}}/>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation11[lang]}}/>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation12[lang]}}/>
            <li style={styles.listItem} dangerouslySetInnerHTML={{__html: text.formation13[lang]}}/>
          </ul>
        </LightBox>
        <div style={{ paddingBottom: '50px'}} />
      </PhotoBox>

    </div>
  )
}
export default Content


//////////////////////

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  listItem: {
    textAlign: 'left'
  }
}
