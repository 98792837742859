import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'

import Platon from '../../Components/images/platon.jpg'
import BG from '../../Components/images/aurique.jpg'

const Therapie = () => {
  const lang = useContext(LanguageContext).language
  const text = {
    therapie1: {
      fr: "Massage aurique et Solides de Platon",
      nl: "Auramassage en Plato’s regelmatige veelvlakken"
    },
    therapie2: {
      fr: "Le massage aurique est une méthode qui permet de remplir d'énergie l'aura de la personne. Il s'agit de faire circuler l'énergie en partant des pieds jusqu'à la tête en vue d'équilibrer et de purifier tous les corps et les systèmes.",
      nl: "De auramassage is een methode om iemands aura met energie te vullen. De massage zorgt voor een stroom van energie van de voeten tot aan het hoofd. Zo worden alle lichamen en systemen gereinigd en in balans gebracht."
    },
    therapie3: {
      fr: "Ce soin est complété par l'activation de cristaux que nous avons dans notre corps et qui se fait par l'utilisation de cristal de roche ayant la forme des Solides de Platon.",
      nl: "De behandeling wordt afgerond door de activatie van kristallen die we in ons lichaam hebben. Hiervoor wordt bergkristal gebruikt, dat de vorm heeft van Plato’s regelmatige veelvlakken."
    },
    therapie4: {
      fr: "Ces deux soins complémentaires équilibrent la personne dans son entièreté, à partir de différentes sortes d'énergies de guérison.",
      nl: "Deze twee complementaire behandelingen brengen de gehele mens in balans, door zijn aura te vullen met verschillende helende energieën."
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <LightBox variant='primary' align='flex-end'>
          <h1 dangerouslySetInnerHTML={{__html: text.therapie1[lang]}}/>
        </LightBox>
        <img src={Platon} style={{ height: 150 }} alt='Platon'/>
      </div>
      <div style={{paddingBottom: '50'}} />
      <PhotoBox Foto={BG} backgroundPosition='top left'>
        <LightBox variant='white' align='flex-end'>
          <div dangerouslySetInnerHTML={{__html: text.therapie2[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end'>
          <div dangerouslySetInnerHTML={{__html: text.therapie3[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end'>
          <div dangerouslySetInnerHTML={{__html: text.therapie4[lang]}}/>
        </LightBox>
        <div style={{paddingBottom: '300px'}} />
      </PhotoBox>
    </div>
  )
}
export default Therapie


//////////////////////

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  }
}

//////////////////////////////////
