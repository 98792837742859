import React from 'react'
import PropTypes from 'prop-types'
import theme from '../../styles/theme'
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer'

const Box = ({ color='text', backgroundColor = 'background1', maxWidth='600px', children }) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      color: theme[color],
      backgroundColor: theme[backgroundColor],
      maxWidth
    }
  }

  const [ref, inView] = useInView({ threshold: 0.5 })
  const controls = useAnimation()
  inView 
    ? controls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 2 },
      })
    : controls.start({
        opacity: 0,
        scale: 0.9,
        transition: { duration: 2 },
      })
    
  
  return (
    <motion.div ref={ref} style={styles.container}
      initial={{ opacity: 0, scale: 0.9 }} animate={controls} >
      {children}
    </motion.div>
  )
}
Box.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.node
}
export default Box
