import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import Traitement1 from '../../Components/images/traitement1.jpg'
import Traitement2 from '../../Components/images/traitement2.jpg'
import Traitement3 from '../../Components/images/traitement3.jpg'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'
import ColorBox from '../../Components/ColorBox'

const Content = () => {

  const lang = useContext(LanguageContext).language
  const text = {
    traitement1: {
      fr: "&nbsp;Approche&nbsp;&nbsp;&nbsp;&nbsp;",
      nl: "&nbsp;Behandeling&nbsp;&nbsp;&nbsp;&nbsp;"
    },
    traitement2: {
      fr: "Le Reiki et les soins énergétiques sont des thérapies alternatives qui utilisent différentes forces de guérison et qui agissent directement sur le corps physique, mais également sur les méridiens, les chakras et les auras.",
      nl: "Reiki en energetische behandelingen zijn alternatieve therapieën, die verschillende helende krachten gebruiken om de energieknopen in de onderdelen en systemen van ons lichaam los te maken. Hierbij wordt niet enkel op het fysieke lichaam gewerkt, maar ook op de meridianen, chakra’s en aura’s."
    },
    traitement3: {
      fr: "Durant le soin, le corps absorbe l'énergie et la transporte là où il en a besoin, débloquant ainsi les nœuds qui se trouvent dans nos systèmes physiques et énergétiques, et permettant à l'énergie de circuler à nouveau librement.",
      nl: "Tijdens de behandeling neemt ons lichaam energie op en brengt ze naar waar ze nodig is. Zo worden knopen in ons fysieke en energetische lichaam ontward en kan de energie weer vrij stromen."
    },
    traitement4: {
      fr: "L’action de ces méthodes permet de retourner aux origines probables causant la maladie ou le déséquilibre. Les effets ne s'arrêtent pas uniquement aux symptômes. <br/>En rééquilibrant les blocages, le Reiki et les soins énergétiques restituent la paix et l'équilibre dans le corps et l'esprit. Ils aident la personne à retrouver la santé physique, mentale, émotionnelle et spirituelle.",
      nl: "Deze methode bereikt de mogelijke oorzaken van de ziekte of het onderliggende onevenwicht. Het helende effect gaat altijd verder dan de symptomen.<br/>Door de energiestroom terug in balans te brengen, brengen Reiki en de energetische behandelingen rust en evenwicht in lichaam en geest. Ze helpen de mens om zijn fysieke, mentale, emotionele en spirituele gezondheid terug te  krijgen."
    },
    traitement5: {
      fr: "Ils s’intègrent dans toutes thérapies déjà existantes et peuvent se pratiquer en complément d’un traitement médical, paramédical ou psychologique/psychiatrique.",
      nl: "De behandelingen kunnen steeds gecombineerd worden met reeds lopende behandelingen en werken complementair bij medische, paramedische, psychologische en psychiatrische therapieën."
    },
    traitement6: {
      fr: "Ces soins alternatifs sont adaptés à tous: bébés, enfants, adolescents, adultes et personnes âgées.",
      nl: "Deze alternatieve behandelingen zijn geschikt voor iedereen: baby’s, kinderen, tieners, volwassenen en ouderen."
    },
    traitement7: {
      fr: "Dans leurs actions thérapeutiques, ces techniques conviennent pour soulager un large spectre de maux physiques et psychiques. Par exemple:",
      nl: "Ze hebben de capaciteit om een breed spectrum van fysische en psychische aandoeningen te behandelen. Bijvoorbeeld:"
    },
    traitement8: {
      fr: "Choc ou trauma, qu’il soit récent ou ancien (le Reiki rééquilibre les systèmes énergétiques de notre corps).",
      nl: "Na een shock of een trauma helpt Reiki bij het herstel van het evenwicht in het energetisch systeem."
    },
    traitement9: {
      fr: "Soulagement d’un large spectre de douleurs physiques tel que maux de tête et migraines, maux de dents, douleurs causées par une fracture…",
      nl: "Verlichting van lichamelijke pijnen zoals hoofpijn, migraine, tandpijn, pijn na botbreuken, enz."
    },
    traitement10: {
      fr: "Accompagnent à la prise en charge d’une maladie chronique, tel que le diabète, SIDA … ou la prise en charge d’un cancer, en diminuant les possibles effets secondaires des médicaments, et en soutenant de manière générale le corps physique et le moral.",
      nl: "Ter ondersteuning van behandeling van chronische aandoeningen, zoals diabetes, aids of kanker, door het tegengaan van medicinale bijwerkingen en door het ondersteunen van de fysieke gesteldheid en de moraal."
    },
    traitement11: {
      fr: "Symptômes d’allergie, stress, fatigue généralisée, burn out, dépression…",
      nl: "Symptomen van allergie, stress, vermoeidheid, burn-out, depressie, enz."
    },
    traitement12: {
      fr: "Brûlures, coupures et irritations de toutes sortes.",
      nl: "Brandwonden, snijwonden en allerhande irritaties."
    },
    traitement13: {
      fr: "Douleurs lors d’une sciatique aiguë (le massage de Breuss calme la douleur).",
      nl: "Pijnen veroorzaakt door acute ischias: de Breuss-massage verzacht de pijn."
    },
    traitement14: {
      fr: "Douleurs causée par la myopathie (le massage êtrique est un soin, qui à long terme, détend et soulage ces douleurs).",
      nl: "Pijnen veroorzaakt door spierziekten: de wezensmassage zal op lange termijn ontspanning en verlichting bieden bij deze pijnen."
    },
    traitement15: {
      fr: "Préparation à une intervention chirurgicale en permettant à la personne de se détendre et d’appréhender l’opération sereinement.",
      nl: "Ter voorbereiding op een chirurgische ingreep: de patiënt kan zich beter ontspannen en zo rustiger de operatie in gaan."
    },
    traitement16: {
      fr: "Soins postopératoires pour aider la personne à récupérer plus rapidement (Reiki).",
      nl: "Na een ingreep kan Reiki het herstel versnellen, zowel fysiek als energetisch."
    },
    traitement17: {
      fr: "Pour accompagner la femme enceinte jusqu’à l’accouchement en l’aidant à vivre sereinement les différents changements qui s’opèrent dans son corps et dans sa vie.",
      nl: "Ter hulp aan zwangere vrouwen de zwangerschap en grote veranderingen in haar lijf en leven rustiger te beleven, tot aan de bevalling."
    },
    traitement18: {
      fr: "Pour accompagner les enfants ayant des problèmes scolaires.",
      nl: "Ter hulp aan kinderen die problemen op school hebben."
    },
    traitement19: {
      fr: "Pour les personnes âgées, qui se sentent seules et isolées.",
      nl: "Ter hulp aan ouderen die zich eenzaam en alleen voelen."
    },
    traitement20: {
      fr: "Pour accompagner la personne mourante à s’en aller sereinement.",
      nl: "Om verlichting bieden aan stervenden en hen te ondersteunen om rustig heen te gaan."
    },
    traitement21: {
      fr: "Important ! Ces soins ne remplacent pas les soins médicaux.",
      nl: "Belangrijk ! Deze therapieën kunnen nooit een medische behandeling vervangen."
    }
  }
  return (
    <div style={styles.root}>
      <div style={styles.header}>
        <ColorBox background='background2'>
          <LightBox variant='white'>
            <h1 dangerouslySetInnerHTML={{__html: text.traitement1[lang]}}/>
          </LightBox>
          <div style={{paddingBottom: '15px'}} />
        </ColorBox>
      </div>

      <PhotoBox Foto={Traitement1}>
        <LightBox variant='white'>
          <div dangerouslySetInnerHTML={{__html: text.traitement2[lang]}}/>
        </LightBox>
        <LightBox variant='dark'>
          <div dangerouslySetInnerHTML={{__html: text.traitement3[lang]}}/>
        </LightBox>
        <LightBox variant='white' maxWidth='600px'>
          <div dangerouslySetInnerHTML={{__html: text.traitement4[lang]}}/>
        </LightBox>
        <div style={{paddingBottom: '200px'}} />
      </PhotoBox>

      <PhotoBox Foto={Traitement2}>
        <LightBox variant='white' align='flex-start'>
          <div dangerouslySetInnerHTML={{__html: text.traitement5[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start'>
          <div dangerouslySetInnerHTML={{__html: text.traitement6[lang]}}/>
        </LightBox>
        <div style={{paddingBottom: '400px'}} />
      </PhotoBox>

      <PhotoBox Foto={Traitement3}>
        <LightBox variant='white' align='flex-start'>
          <div dangerouslySetInnerHTML={{__html: text.traitement7[lang]}}/>
        </LightBox>
        <LightBox variant='dark' align='flex-end' maxWidth='500px'>
          <ul>
            <li dangerouslySetInnerHTML={{__html: text.traitement8[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement9[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement10[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement11[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement12[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement13[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement14[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement15[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement16[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement17[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement18[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement19[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement20[lang]}}/>
          </ul>
        </LightBox>
        <LightBox variant='white' align='flex-start'>
          <i><div dangerouslySetInnerHTML={{__html: text.traitement21[lang]}}/></i>
        </LightBox>
      </PhotoBox>
    </div>
  )
}
export default Content


//////////////////////
const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    textAlign: 'left',
  },
  header: {
    width: '100%',
    maxWidth: 800
  }
}
