import React from 'react'
import bg from './logo_bg_1000-min.png'
import css from './styles.module.css'
import { motion } from "framer-motion"

const LogoBG = () => {
  return (
    <motion.div style={styles.container}
      initial={{ opacity: 0, scale: 0.7 }} animate={{ opacity: 0.9, scale: 1.0}} transition={{ duration: 12}}>
      <img src={bg} className={css.rotate} alt='' />
    </motion.div>
  )
}
export default LogoBG


//////////////////////

const styles = {
  container: {
    paddingTop: 450,
  }
}

//////////////////////////////////