import React from 'react'
import theme from '../styles/theme'


const Footer = () => {
  return (
    <div style={styles.container}>
      <div>
        <h5><a href='mailto:laurence@delevensspiraal.be'>laurence@delevensspiraal.be</a></h5>
        <h5>Tel: (+32) 472 78 79 46</h5>
      </div>
      <div>
        <h3>De LevensSpiraal</h3>
        <h5>Grote Beerstraat 52 2018 Antwerpen</h5>
      </div>
      <div>
        <h5>BTW: BE 0537292007</h5>
        <h5>ondernemingsnummer: 0537.292.007</h5>
      </div>
    </div>
  )
}
export default Footer


//////////////////////

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    color: theme.textSecondary,
    backgroundColor: theme.transparent.primary,
    borderTop: '1px solid',
    padding: 15
    }
}

//////////////////////////////////