import React from 'react'
import PropTypes from 'prop-types'
import theme from '../styles/theme'
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer'

const LightBox = ({ children, variant='white', align, maxWidth='400px' }) => {

  const style = {
    maxWidth,
    padding: 10,
    marginBottom: 10,
    alignSelf: align ? align : undefined
  }
  switch (variant) {
    case 'primary':
      style.color = theme.textSecondary
      style.background = theme.transparent.white
      break
    case 'dark':
      style.color = theme.textSecondary
      style.background = theme.transparent.white
      break
    case 'white': 
    default:
      style.color = theme.primary
      style.background = theme.transparent.dark
  }
  const [ref, inView] = useInView({ threshold: 0.5 })
  const controls = useAnimation()
  inView 
    ? controls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 2 },
      })
    : controls.start({
        opacity: 0,
        scale: 0.9,
        transition: { duration: 2 },
      })
    
  
  return (
    <motion.div ref={ref} style={style}
      initial={{ opacity: 0, scale: 0.9 }} animate={controls} >
      <div>{children}</div>
    </motion.div>
  )
}
LightBox.propTypes = {
  variant: PropTypes.string.isRequired,
  align: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.node.isRequired
}
export default LightBox
