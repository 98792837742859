import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import Mikao from '../../Components/images/mikao.jpg'
import Traitement3 from '../../Components/images/traitement3.jpg'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'
import ColorBox from '../../Components/ColorBox'

const Content = () => {

  const lang = useContext(LanguageContext).language
  const text = {
    text1: {
      fr: "&nbsp;&nbsp;&nbsp;&nbsp;Formations",
      nl: "&nbsp;&nbsp;&nbsp;&nbsp;Opleidingen"
    },
    text2: {
      fr: "En tant qu'enseignante REIKI, je donne les formations de niveau 1 de la méthode de Reiki USUI.",
      nl: "Als lesgevend Reikimeester verzorg ik opleidingen niveau I van de USUI-Reikimethode."
    },
    text2a: {
      fr: "Ceci ce fait sur demande. N’hésitez pas à me contacter si vous êtes intéressé/e/s.",
      nl: "Voorlopig bied ik deze opleidingen alleen in het Frans - mijn moeder taal - aan."
    },
    text3: {
      fr: "Formation REIKI niveau I",
      nl: "Formation REIKI niveau I"
    },
    text4: {
      fr: "Le REIKI est une méthode de guérison naturelle qui agit sur la totalité de l’être, aux niveaux physique, énergétique, émotionnel, mental et spirituel. C’est une méthode simple, puissante et douce à la fois, qui permet de canaliser l’énergie vitale universelle et de l’utiliser sur soi ou sur autrui par impositions des mains.",
      nl: "Le REIKI est une méthode de guérison naturelle qui agit sur la totalité de l’être, aux niveaux physique, énergétique, émotionnel, mental et spirituel. C’est une méthode simple, puissante et douce à la fois, qui permet de canaliser l’énergie vitale universelle et de l’utiliser sur soi ou sur autrui par impositions des mains."
    },
    text5: {
      fr: "Pratiquer le REIKI est un beau voyage à la découverte de soi et des autres. C’est également un choix d’engagement vis-à-vis de soi-même, de la terre et de l’univers à vouloir progresser vers l’éveil.",
      nl: "Pratiquer le REIKI est un beau voyage à la découverte de soi et des autres. C’est également un choix d’engagement vis-à-vis de soi-même, de la terre et de l’univers à vouloir progresser vers l’éveil."
    },
    text6: {
      fr: "Le REIKI permet de prendre soin de soi et de se soigner soi-même.<br/><br/>C’est un beau cadeau à se faire et à faire à l’univers.",
      nl: "Le REIKI permet de prendre soin de soi et de se soigner soi-même.<br/><br/>C’est un beau cadeau à se faire et à faire à l’univers."
    },
    text7: {
      fr: "Programme:",
      nl: "Programme:"
    },
    text8: {
      fr: "<li>Histoire de la redécouverte du REIKI par MIKAO USUI,</li><li>Définition et description,</li><li>Les 5 préceptes de base,</li><li>Apprentissage des positions des mains pour l’auto-traitement,</li><li>Apprentissage des positions des mains pour un soin sur l’autre,</li><li>Pratique d’un soin REIKI sur soi/auto-guérison,</li><li>Pratique d’un soin REIKI complet sur une personne,</li><li>Méditations pour le REIKI,</li><li>Exercices d’ancrage,</li><li>Initiation individuelle.",
      nl: "<li>Histoire de la redécouverte du REIKI par MIKAO USUI,</li><li>Définition et description,</li><li>Les 5 préceptes de base,</li><li>Apprentissage des positions des mains pour l’auto-traitement,</li><li>Apprentissage des positions des mains pour un soin sur l’autre,</li><li>Pratique d’un soin REIKI sur soi/auto-guérison,</li><li>Pratique d’un soin REIKI complet sur une personne,</li><li>Méditations pour le REIKI,</li><li>Exercices d’ancrage,</li><li>Initiation individuelle."
    },
    text9: {
      fr: "Prix: 250 euros",
      nl: "Prix: 250 euros"
    },
    text10: {
      fr: "Date: sur un WE<br/>&nbsp;Après 21 jours à la suite du cours aura lieu une soirée de partage avec questions-réponses et traitement REIKI en groupe<br/>(date à fixer avec le groupe).",
      nl: "Date: sur un WE<br/>&nbsp;Après 21 jours à la suite du cours aura lieu une soirée de partage avec questions-réponses et traitement REIKI en groupe<br/>(date à fixer avec le groupe)."
    },
    text11: {
      fr: "Lieu: De LevensSpiraal / Grote Beerstraat 52 – 2018 Anvers.<br/>",
      nl: "Lieu: De LevensSpiraal / Grote Beerstraat 52 – 2018 Anvers.<br/>"
    },
    text12: {
      fr: "Horaire: Samedi et dimanche de 9h00 à 17h30.<br/>Pensez à prendre un pique-nique que nous mangerons ensemble sur place (verres, tasses, assiettes, couverts et serviettes disponibles).",
      nl: "Horaire: Samedi et dimanche de 9h00 à 17h30.<br/>Pensez à prendre un pique-nique que nous mangerons ensemble sur place (verres, tasses, assiettes, couverts et serviettes disponibles)."
    },
    text13: {
      fr: "A prendre : <ul><li>Tenue confortable<br/>(style training de couleur claire),</li><li>Chaussettes,</li><li>De quoi écrire.</li></ul>",
      nl: "A prendre : <ul><li>Tenue confortable<br/>(style training de couleur claire),</li><li>Chaussettes,</li><li>De quoi écrire.</li></ul>"
    }
  }
  return (
    <div style={styles.root}>
      <ColorBox flexDirection='row'>
        <img src={Mikao} style={{ height: 250  }} alt='Miako Usui'/>
        <div>
          <LightBox variant='primary' align='flex-end'>
            <h1 dangerouslySetInnerHTML={{__html: text.text1[lang]}}/>
          </LightBox>
          <LightBox variant='white'>
            <div dangerouslySetInnerHTML={{__html: text.text2[lang]}}/>
          </LightBox>
          <LightBox variant='white'>
            <div dangerouslySetInnerHTML={{__html: text.text2a[lang]}}/>
          </LightBox>
        </div>
        <div style={{paddingBottom: '15px'}} />
      </ColorBox>

      <PhotoBox Foto={Traitement3}>
        <LightBox variant='primary' align='flex-start'>
          <h1 dangerouslySetInnerHTML={{__html: text.text3[lang]}}/>
        </LightBox>
        <LightBox variant='dark' align='flex-end' maxWidth='600px'>
          <div dangerouslySetInnerHTML={{__html: text.text4[lang]}}/>
        </LightBox>
        <LightBox variant='dark' align='flex-end' maxWidth='500px'>
          <div dangerouslySetInnerHTML={{__html: text.text5[lang]}}/>
        </LightBox>
        <LightBox variant='dark' align='flex-end'>
          <div dangerouslySetInnerHTML={{__html: text.text6[lang]}}/>
        </LightBox>


        <LightBox variant='white' align='flex-end'>
          <h2 dangerouslySetInnerHTML={{__html: text.text7[lang]}}/>
          <ul dangerouslySetInnerHTML={{__html: text.text8[lang]}}/>
        </LightBox>
        <LightBox variant='dark' align='flex-end'>
          <ul>
            <li dangerouslySetInnerHTML={{__html: text.text9[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.text10[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.text11[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.text12[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.text13[lang]}}/>
          </ul>
        </LightBox>

        <div style={{paddingBottom: '200px'}} />
      </PhotoBox>
    </div>
  )
}
export default Content


//////////////////////
const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    textAlign: 'left',
  },
  listItem: {
    textAlign: 'left'
  }
}
