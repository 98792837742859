import React from 'react'
import PropTypes from 'prop-types'
import theme from '../../styles/theme'
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

const Button = ({ name, link, color }) => {
  const styles = {
    container: {
      display: 'flex',
      padding: 12,
      borderRadius: 2,
      margin: 10,
    },
    content: {
      color: theme.textSecondary,
      textDecoration: 'none',
      textShadow: '0 0 2px #FFF, 0 0 4px #FFF, 0 0 8px #FFF'
    }
  }
  return (
    <div >
      <motion.div initial={{ backgroundColor: theme[color] }} style={styles.container}
        whileHover={{ scale: 1.1, backgroundColor: theme.primary,transition: { duration: 1} }}
        whileTap={{ scale: 0.9, x: "0px", y: "5px", transition: { duration: 1}}}
        >
        <Link to={link} style={styles.content}>
            { name }
        </Link>
      </motion.div>
    </div>
  )
}
Button.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}
export default Button
