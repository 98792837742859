import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import Box from '../../Components/Box'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'
import home1 from '../../Components/images/home1.jpg'
import Cabinet1 from '../../Components/images/cabinet1.jpg'
import TherapieSelectieBox from '../Therapies/TherapieSelectieBox'

const Welcome = () => {

  const lang = useContext(LanguageContext).language
  const text = {
    spreuk1: {
      fr: '« On ne voit bien qu’avec le cœur.<br/>L’essentiel est invisible pour les yeux. » ',
      nl: '« Alleen met het hart kan je goed zien.<br/>Het wezenlijke is voor de ogen onzichtbaar. »' },
    spreuk2: {
      fr: 'Antoine de Saint-Exupéry',
      nl: 'Antoine de Saint-Exupéry' },

    h1: { fr: 'REIKI & SOINS ENERGETIQUES', nl: 'REIKI & ENERGETISCHE BEHANDELING' },
    naam: { fr: 'Laurence TSCHANZ', nl: 'Laurence TSCHANZ' },
    beroep: { fr: 'Infirmière & thérapeute REIKI', nl: 'Verpleegkundige & REIKI therapeut' },

    desc1: {
      fr: 'Avez-vous envie de vous connecter à votre âme et d’écouter ses messages ? <br />De prendre en main votre santé et d’explorer de nouvelles dimensions ?<br />De soulager vos douleurs et de prévenir les maladies ?<br />De compléter un traitement physique ou psychologique ?<br />Avez-vous besoin de vous détendre et de prendre soin de vous ? ',
      nl: 'Zin om in contact te komen met uw ziel en te luisteren naar haar stem ?<br />Om uw gezondheid in eigen handen te nemen en nieuwe dimensies te ontdekken ?<br />Om pijnen te verzachten en ziektes te voorkomen ?<br />Om een bestaande lichamelijke of psychische behandeling mee te ondersteunen ?<br />Behoefte om u te ontspannen en uzelf te verzorgen ?' },
    desc2: {
      fr: 'Ces thérapies alternatives adaptées à chacun/e s’adressent aux personnes intéressées à jouer un rôle actif vis-à-vis de leur santé et servent à éveiller les potentiels de guérison que chacun/e porte en soi.<br/>Elles prennent en compte la personne dans sa globalité physique, émotionnelle, psychique et spirituelle.<br/><br/>Tout en pratiquant ces différents soins énergétiques, mon but est de vous accompagner et vous soutenir le long de votre chemin de développement  personnel et d’auto-guérison.',
      nl: 'Deze alternatieve therapieën richten zich tot iedereen die een actieve rol wil spelen in het verbeteren van zijn eigen gezondheid en ondersteunen het genezingspotentieel dat iedereen in zich draagt.<br/>Ze houden rekening met de persoon in zijn geheel: fysiek, emotioneel, psychisch en spiritueel.<br/><br/>Met deze therapieën en energetische massages wil ik u begeleiden en helpen op uw persoonlijke weg naar zelfgenezing.' },
  }

  return (
    <div style={styles.root}>
      <Box backgroundColor='background2'>
        <h3 dangerouslySetInnerHTML={{__html: text.spreuk1[lang]}} style={{ padding: 10}} />
        <h5 dangerouslySetInnerHTML={{__html: text.spreuk2[lang]}} style={{ padding: 10}} />
      </Box>

      <div style={{paddingBottom: '20px'}} />

      <PhotoBox Foto={home1} backgroundPosition='top left'>
        <div style={{paddingBottom: '200px'}} />
        <LightBox variant='white'>
          <h1>De LevensSpiraal</h1>
        </LightBox>
        <LightBox variant='dark'>
          <h3>{text.h1[lang]}</h3>
        </LightBox>
        <div style={{paddingBottom: '200px'}} />
        <LightBox variant='white' maxWidth='600px'>
          <h4>{text.naam[lang]}</h4>
          <h4>{text.beroep[lang]}</h4>
        </LightBox>
      </PhotoBox>

      <div style={styles.horizontaal}>
        <Box color='textSecondary' backgroundColor='background1' maxWidth='800px'>
          <h4 dangerouslySetInnerHTML={{__html: text.desc1[lang]}} style={{ padding: 10}}/>
        </Box>
      </div>

      <div style={{ maxWidth: 800 }}>
        <TherapieSelectieBox lang={lang}/>
      </div>

      <div style={styles.horizontaal}>
        <Box color='textSecondary' backgroundColor='background1' maxWidth='800px'>
          <h4 dangerouslySetInnerHTML={{__html: text.desc2[lang]}} style={{ padding: 10}} />
        </Box>
      </div>

    </div>
  )
}
export default Welcome


//////////////////////

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '960px'
  },
  horizontaal: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingBottom: 20
  },
  cabinet1: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '600px',
    height: '400px',
    backgroundImage: `url(${Cabinet1})`,
    backgroundPosition: 'bottom',
    padding: 20,
  },
}
