import React from 'react'
import Proptypes from 'prop-types'
import { motion } from "framer-motion"
import { NavLink } from "react-router-dom"
import theme from '../styles/theme'


const HeaderItem = ({ link, name }) => {
  return (
    <motion.div
      style={styles.container}
      whileHover={{ scale: 1.1, transition: { duration: 1} }}
      whileTap={{ scale: 0.9, x: "0px", y: "5px", transition: { duration: 1} }}
    >
      <NavLink exact={true} to={link} style={styles.base} activeStyle={styles.active}>
        {name}
      </NavLink>
    </motion.div>
  )
}
HeaderItem.propTypes = {
  link: Proptypes.string.isRequired,
  name: Proptypes.string.isRequired
}
export default HeaderItem

const styles = {
  container: {
  },
  base: {
    textDecoration: 'none',
    marginLeft: 5,
    marginRight: 5,
    padding: '3px 10px',
  },
  active: {
    color: theme.text,
    backgroundColor: theme.background2,
  }
}
