import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'

import BG from '../../Components/images/breuss.jpg'

const Therapie = () => {
  const lang = useContext(LanguageContext).language
  const text = {
    therapie1: {
      fr: "Massage de Breuss",
      nl: "Breuss-massage"
    },
    therapie2: {
      fr: "Le massage de Breuss est une thérapie dorsale qui consiste en un massage doux sur la colonne vertébrale.",
      nl: "De Breuss-massage is een rugbehandeling die een zachte massage van de wervelkolom inhoudt."
    },
    therapie3: {
      fr: "C'est un massage manuel et énergétique qui peut résoudre des blocages psychiques et corporels. Il entraîne la régénération de la colonne vertébrale et des disques intervertébraux et améliore ainsi le fonctionnement de tous les organes.",
      nl: "Deze manuele energetische therapie, die psychische en fysieke blokkages kan opheffen, bevordert het herstel van de wervelkolom en de tussenwervelschijven. Zo wordt de werking van alle organen gestimuleerd."
    },
    therapie4: {
      fr: "De plus, il procure un bien-être général.<br/><br/>Il se fait avec une huile essentielle particulière.<br/><br/>Ce soin est particulièrement adapté pour les personnes ayant des maux de dos.",
      nl: "Bovendien zorgt de massage voor een verbetering van het algemene gevoel van welzijn.<br/><br/>Voor deze verzorging worden specifieke etherische oliën gebruikt.<br/><br/>Deze massage is bijzonder aangewezen voor mensen met rugproblemen."
    }
  }

  return (
    <div style={styles.container}>
      <PhotoBox Foto={BG} backgroundPosition='top right'>
        <LightBox variant='primary' align='flex-start'>
          <h1 dangerouslySetInnerHTML={{__html: text.therapie1[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start'>
          <div dangerouslySetInnerHTML={{__html: text.therapie2[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start'>
          <div dangerouslySetInnerHTML={{__html: text.therapie3[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start'>
          <div dangerouslySetInnerHTML={{__html: text.therapie4[lang]}}/>
        </LightBox>
        <div style={{paddingBottom: '400px'}} />
      </PhotoBox>
    </div>
  )
}
export default Therapie


//////////////////////

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}

//////////////////////////////////
