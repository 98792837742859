import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import '../styles/init.scss'
import Header from '../Header'
import Footer from '../Footer'
import Home from '../pages/Home'
import Qui from '../pages/Qui'
import Approche from '../pages/Approche'
import Formations from '../pages/Formations'
import Therapies from '../pages/Therapies'
import Info from '../pages/Info'

import { LanguageProvider } from './languageContext'

const App = () => {
  const getDefaultLanguage = () => {
    switch(window.navigator.language.substr(0,2)){
      case 'nl':
        return 'nl'
      default:
        return 'fr'
    }
  }
  const [language, setLanguage] = useState(getDefaultLanguage())
  return (
    <Router>
      <LanguageProvider value={{ language, setLanguage }}>
        <div style={styles.container}>

          <Header />

          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/qui">
              <Qui />
            </Route>
            <Route path="/approche">
              <Approche />
            </Route>
            <Route path="/formations">
              <Formations />
            </Route>
            <Route path="/therapies">
              <Therapies />
            </Route>
            <Route path="/info">
              <Info />
            </Route>
            <Route component={Home} />
          </Switch>

          <Footer />

        </div>
      </LanguageProvider>
    </Router>
  )
}
export default App

const styles = {
  container: {
    textAlign: 'center'
  }
}
