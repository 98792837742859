import React, { useContext } from 'react'
import { Link } from "react-router-dom"
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import HeaderItem from './HeaderItem'
import theme from '../styles/theme'
import LanguageButton from './LanguageButton'
import languageContext from '../App/languageContext'


const Header = () => {
  const lang = useContext(languageContext).language
  const text = {
    navItem1: { fr: "Accueil", nl: "Welkom" },
    navItem2: { fr: "Qui", nl: "Wie ben ik" },
    navItem3: { fr: "Approche", nl: "Behandelingen" },
    navItem4: { fr: "Therapies", nl: "Therapieën" },
    navItem5: { fr: "Formations", nl: "Opleiding volgen?" },
    navItem6: { fr: "Infos pratiques", nl: "Praktische info" },
  }
  return (
    <Navbar expand="lg" fixed="top" className="border-bottom border-primary pb-2" style={styles.root}>
      <Navbar.Brand>
        <Link to='/' style={styles.brand}>De LevensSpiraal</Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <HeaderItem link='/' name={text.navItem1[lang]}/>
          <HeaderItem link='/qui' name={text.navItem2[lang]}/>
          <HeaderItem link='approche' name={text.navItem3[lang]}/>
          <HeaderItem link='therapies' name={text.navItem4[lang]}/>
          <HeaderItem link='formations' name={text.navItem5[lang]}/>
          <HeaderItem link='info' name={text.navItem6[lang]}/>
        </Nav>
        <LanguageButton />
      </Navbar.Collapse>
    </Navbar>
  )
}
export default Header


const styles = {
  root: {
    backgroundColor: theme.background1,
  },
  brand: {
    fontWeight: 'bold',
    textDecoration: 'none',
    marginLeft: 5,
    marginRight: 5,
    paddingLeft: 10,
    paddingRight: 10
  },
}
