import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'

import BG from '../../Components/images/dorn.jpg'

const Therapie = () => {
  const lang = useContext(LanguageContext).language
  const text = {
    therapie1: {
      fr: "Massage de Dorn",
      nl: "Dorn-massage"
    },
    therapie2: {
      fr: "Le massage de Dorn est une thérapie dorsale. Il s'agit d'un traitement neuromusculaire doux des vertèbres et des articulations.",
      nl: "De Dorn-massage is een zachte neuromusculaire behandeling van de wervels en de gewrichten."
    },
    therapie3: {
      fr: "Il peut être utilisé pour traiter des problèmes qui sont directement ou indirectement en lien avec la colonne vertébrale.",
      nl: "De techniek kan gebruikt worden voor de behandeling van problemen die rechtstreeks of onrechtstreeks verband houden met de wervelkolom."
    },
    therapie4: {
      fr: "Ce soin consiste à intervenir sur l'alignement des vertèbres et des articulations en effectuant, au besoin, des pressions douces pour aider à les réajuster. <br/><br/>Il apporte un soulagement physique et une détente généralisée.",
      nl: "De behandeling richt zich op de onderlinge positie van de wervels en gewrichten: daar waar nodig wordt deze positie gecorrigeerd door een zachte druk uit te oefenen.<br/><br/>Dit zorgt voor een fysieke verlichting en een algemeen gevoel van rust."
    },
    therapie5: {
      fr: "Pour ce traitement, le client/la cliente est en tenue de jogging et devra parfois changer de position.<br/><br/>Sur le dos, une huile essentielle spécifique est utilisée.",
      nl: "Tijdens deze verzorging blijft de cliënt gekleed in joggingpak en dient hij meermaals van houding te veranderen."
    }
  }

  return (
    <div style={styles.container}>
      <PhotoBox Foto={BG} backgroundPosition='top left'>
        <LightBox variant='primary' align='flex-end'>
          <h1 dangerouslySetInnerHTML={{__html: text.therapie1[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start' maxWidth='300px'>
          <div dangerouslySetInnerHTML={{__html: text.therapie2[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end' maxWidth='350px'>
          <div dangerouslySetInnerHTML={{__html: text.therapie3[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end' maxWidth='300px'>
          <div dangerouslySetInnerHTML={{__html: text.therapie4[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-start' maxWidth='300px'>
          <div dangerouslySetInnerHTML={{__html: text.therapie5[lang]}}/>
        </LightBox>
        <div style={{paddingBottom: '200px'}} />
      </PhotoBox>
    </div>
  )
}
export default Therapie


//////////////////////

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}

//////////////////////////////////
