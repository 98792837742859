import React from 'react'
import PropTypes from 'prop-types'
import theme from '../styles/theme'

const ColorBox = ({ children, background='background1', flexDirection='column' }) => {
  const styles = {
    container: {
      background: theme[background],
      display: 'flex',
      flexDirection,
      marginBottom: 20
    }
  }
  return (
    <div style={styles.container}>
      {children}
    </div>
  )
}
ColorBox.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  flexDirection: PropTypes.string,
}

export default ColorBox
