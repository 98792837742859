const theme = {
  primary: '#fff4e6',
  text: '#fff9f2',
  textSecondary: '#bf6a00',
  white: '#FBFBFB',
  background1: '#fcf1e3',
  background2: '#ffb75c',
  transparent: {
    white: '#FFFFFFD0',
    dark: '#000000C0',
    primary :'#e57e0060',
  },
  reikiColor1: '#f87676',
  reikiColor2: '#fda673',
  reikiColor3: '#ecf575',
  reikiColor4: '#8bff9c',
  reikiColor5: '#40f4ff',
  reikiColor6: '#ed40ff',
  reikiColor7: '#fbaaff',
}
export default theme