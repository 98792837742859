import React, { useContext } from 'react'
import languageContext from '../App/languageContext'

const LanguageButton = () => {
  const language = useContext(languageContext)
  const handleLanguage = () => {
    if (language.language === 'fr') {
      language.setLanguage('nl')
    } else {
      language.setLanguage('fr')
    }
    
  }

  let fr = 'text-primary font-weight-bold'
  let nl = 'text-muted font-weight-light'
  if( language.language === 'nl'){
    nl = 'text-primary font-weight-bold'
    fr = 'text-muted font-weight-light'
  }

  return (
    <div style={styles.container} onClick={handleLanguage}>
      <span className={fr}>FR</span>/<span className={nl}>NL</span>
    </div>
  )
}
export default LanguageButton


//////////////////////

const styles = {
  container: {
    cursor: 'pointer',
  }
}

//////////////////////////////////