import React from 'react'
import PropTypes from 'prop-types'
import Cabinet1 from '../../Components/images/cabinet1.jpg'
import PhotoBox from '../../Components/PhotoBox'
import Button from '../../Components/Button'


const TherapieSelectieBox = ({lang}) => {

  const text = {
    btn1: {
      fr: 'Reiki',
      nl: 'Reiki'
    },
    btn2: {
      fr: 'Harmonisation Energétique',
      nl: 'Energetische Harmonisatie'
    },
    btn3: {
      fr: 'Massage Aurique & Solides de Platon',
      nl: 'Auramassage & Plato’s regelmatige veelvlakken'
    },
    btn4: {
      fr: 'Massage Etrique',
      nl: 'Wezensmassage'
    },
    btn5: {
      fr: 'Massage de Dorn',
      nl: 'Dorn-massage'
    },
    btn6: {
      fr: 'Massage de Breuss',
      nl: 'Breuss-massage'
    },
  }
  return (
    <PhotoBox Foto={Cabinet1} alignItems='center' backgroundPosition='center'>
      <div style={styles.buttonBox}>
        <Button name={text.btn1[lang]} link='/therapies?therapie=reiki' color='reikiColor1' />
        <Button name={text.btn2[lang]} link='/therapies?therapie=harmonisation' color='reikiColor2' />
        <Button name={text.btn3[lang]} link='/therapies?therapie=aurique' color='reikiColor3' />
        <Button name={text.btn4[lang]} link='/therapies?therapie=etrique' color='reikiColor5' />
        <Button name={text.btn5[lang]} link='/therapies?therapie=dorn' color='reikiColor6' />
        <Button name={text.btn6[lang]} link='/therapies?therapie=breuss' color='reikiColor7' />
      </div>
    </PhotoBox>
  )
}
TherapieSelectieBox.propTypes = {
  lang: PropTypes.string.isRequired,
}
export default TherapieSelectieBox


//////////////////////

const styles = {
  buttonBox :{
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  }
}
