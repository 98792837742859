import React from 'react'
import PropTypes from 'prop-types'

const PhotoBox = ({ Foto, children, backgroundPosition='bottom left', alignItems='flex-end', maxWidth='800px' }) => {
  const style = {
    backgroundImage: `url(${Foto})`,
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    maxWidth,
    backgroundPosition,
    display: 'flex',
    flexDirection: 'column',
    alignItems,
    marginBottom: 20
  }
  return (
    <div style={style}>
      {children}
    </div>
  )
}
PhotoBox.propTypes = {
  children: PropTypes.node.isRequired,
  Foto: PropTypes.node.isRequired,
  backgroundPosition: PropTypes.string,
  alignItems: PropTypes.string
}
export default PhotoBox