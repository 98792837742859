import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'

import BG from '../../Components/images/harmonisation.jpg'

const Therapie = () => {
  const lang = useContext(LanguageContext).language
  const text = {
    therapie1: {
      fr: "Massage Etrique",
      nl: "Wezensmassage"
    },
    therapie2: {
      fr: "Le massage étrique est un massage particulier et holistique qui se fait en harmonie avec l'Être intérieur.",
      nl: "Wezensmassage is een specifieke en holistische massage, die in harmonie met het innerlijke wezen wordt uitgevoerd."
    },
    therapie3: {
      fr: "Il manifeste ses bienfaits au niveau de tous les plans de la personne: régénérescence cellulaire et revitalisation; harmonisation des corps et des circuits vitaux; rééquilibrage énergétique; ouverture des plans de conscience; élimination des toxines énergétiques et chimiques; détente, relaxation, apaisement et bien-être.",
      nl: "De weldadige effecten manifesteren zich in de gehele mens: celregeneratie en - revitalisatie, harmoniseren van de energetische lichamen en levensstromen, energetische balans, openstellen van de diverse lagen van het bewustzijn, en verwijdering van slechte energieën en toxische stoffen. De massage brengt ontspanning, rust, kalmte en welzijn."
    },
    therapie4: {
      fr: "C'est un massage doux qui se fait avec des huiles essentielles sur les pieds, les jambes et le dos. ",
      nl: "Het is een zachte massage, die met etherische oliën op de voeten, de benen en de rug wordt gegeven."
    }
  }

  return (
    <div style={styles.container}>
      <PhotoBox Foto={BG} backgroundPosition='top left'>
        <LightBox variant='primary' align='flex-end'>
          <h1 dangerouslySetInnerHTML={{__html: text.therapie1[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end' maxWidth='550px'>
          <div dangerouslySetInnerHTML={{__html: text.therapie2[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end' maxWidth='550px'>
          <div dangerouslySetInnerHTML={{__html: text.therapie3[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end'>
          <div dangerouslySetInnerHTML={{__html: text.therapie4[lang]}}/>
        </LightBox>
        <div style={{paddingBottom: '200px'}} />
      </PhotoBox>
    </div>
  )
}
export default Therapie


//////////////////////

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}

//////////////////////////////////
