import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import LanguageContext from '../../App/languageContext'
import TherapieSelectieBox from './TherapieSelectieBox'
import LightBox from '../../Components/LightBox'
import ColorBox from '../../Components/ColorBox'
import Reiki from './Reiki'
import Harmonisation from './Harmonisation'
import Aurique from './Aurique'
import Etrique from './Etrique'
import Dorn from './Dorn'
import Breuss from './Breuss'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Content = () => {
  const lang = useContext(LanguageContext).language
  const text = {
    therapies1: {
      fr: "&nbsp;&nbsp;&nbsp;&nbsp;Therapies",
      nl: "&nbsp;&nbsp;&nbsp;&nbsp;Therapie"
    }
  }

  const query = useQuery()
  const therapie = query.get("therapie")
  let Therapie = Reiki
  switch (therapie) {
    case 'reiki':
      Therapie = Reiki
      break
    case 'harmonisation':
      Therapie = Harmonisation
      break
    case 'aurique':
      Therapie = Aurique
      break
    case 'etrique':
      Therapie = Etrique
      break
    case 'dorn':
      Therapie = Dorn
      break
    case 'breuss':
      Therapie = Breuss
      break
    default:
      break
  }

  return (
    <div style={styles.root}>
      <div>
        <ColorBox background='background2'>
          <LightBox variant='white'>
            <h1 dangerouslySetInnerHTML={{__html: text.therapies1[lang]}}/>
          </LightBox>
          <div style={{paddingBottom: '15px'}} />
        </ColorBox>

        <TherapieSelectieBox lang={lang} />

        <Therapie />

      </div>
    </div>
  )
}
export default Content


//////////////////////
const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    textAlign: 'left',
  }
}
