import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'

import BG from '../../Components/images/etrique.jpg'

const Harmonisation = () => {
  const lang = useContext(LanguageContext).language
  const text = {
    traitement1: {
      fr: "Harmonisation énergétique",
      nl: "Energetische harmonisatie"
    },
    traitement2: {
      fr: "L'harmonisation énergétique est un soin qui consiste à charger et nettoyer l'aura en stimulant la circulation de l'énergie au niveau des centres énergétiques du corps humain (les chakras) et de tous les plans de l'être.",
      nl: "Energetische harmonisatie is een techniek die de aura voedt en reinigt, door het stimuleren van de energiestromen in alle energetische centra in het lichaam (de chakra&#39;s) en in alle lagen van ons wezen."
    },
    traitement3: {
      fr: "Il se fait étape par étape, partant des pieds pour finir vers la tête, en posant les mains sur différentes parties du corps de la personne ce qui permet d'équilibrer successivement les sept centres énergétiques (chakras).",
      nl: "Dit gebeurt stap voor stap, vertrekkend van de voeten naar het hoofd, door de handen op verschillende lichaamsdelen van de persoon te leggen. Zo worden de zeven energetische centra een voor een terug in balans gebracht."
    }
  }

  return (
    <div style={styles.container}>
      <PhotoBox Foto={BG} backgroundPosition='top left'>
        <LightBox variant='primary' align='flex-end'>
          <h1 dangerouslySetInnerHTML={{__html: text.traitement1[lang]}}/>
        </LightBox>
        <LightBox variant='white' align='flex-end'>
          <div dangerouslySetInnerHTML={{__html: text.traitement2[lang]}}/>
        </LightBox>
        <div style={{paddingBottom: '300px'}} />
        <LightBox variant='white' align='flex-end'>
          <div dangerouslySetInnerHTML={{__html: text.traitement3[lang]}}/>
        </LightBox>
        <div style={{paddingBottom: '50px'}} />
      </PhotoBox>
    </div>
  )
}
export default Harmonisation


//////////////////////

const styles = {
  container: {
    display: 'flex',
  }
}

//////////////////////////////////
