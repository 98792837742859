import React from 'react'
import Content from './Content'
import { Parallax, Background } from 'react-parallax'
import LogoBG from '../../Components/LogoBG'
import ScrollToTopOnMount from '../../Components/ScrollToTopOnMount'

const Qui = () => {
  return (
    <div style={styles.container}>
      <ScrollToTopOnMount />
      <Parallax strength={1000} >
      <Content />
      <Background>
        <LogoBG />
      </Background>
    </Parallax>
    </div>
  )
}
export default Qui

const styles = {
  container: {
    paddingTop: 55
  }
}
