import React, { useContext } from 'react'
import LanguageContext from '../../App/languageContext'
import Traitement1 from '../../Components/images/traitement1.jpg'
import Traitement2 from '../../Components/images/img_3875.jpg'
import LightBox from '../../Components/LightBox'
import PhotoBox from '../../Components/PhotoBox'

const Content = () => {

  const lang = useContext(LanguageContext).language
  const text = {
    traitement2: {
      fr: "TARIF",
      nl: "TARIEF"
    },
    traitement3: {
      fr: "Une consultation dure de <b>1h30 à 2h</b>.<br/><br/>Le tarif est de <b>80 euros pour la consultation pour les adultes</b> et de <b>60 euros pour les enfants</b>.<br/><br/>Le massage êtrique coûte <b>90 euros</b>.",
      nl: "Een consultatie duurt <b>1u30 à 2 uur</b>.<br/><br/>Het kost <b>80 euro per consultatie voor volwassenen</b> en <b>60 euro voor kinderen</b>.<br/><br/>De Wezensmassage kost <b>90 euro.</b>"
    },
    traitement4: {
      fr: "Possibilité de faire des BONS CADEAU.<br/><br/>Je parle français, néerlandais, anglais et italien.<br/><br/><b>Sur rendez-vous</b> au <b>(+32) 472 78 79 46</b>.",
      nl: "Mogelijkheden een CADEAUBON te vragen.<br/><br/>Ik spreek Frans, Nederlands, Engels en Italiaans.<br/><br/><b>Enkel op afspraak</b>, op <b>(+32) 472 78 79 46</b>"
    },
    traitement5: {
      fr: "ANVERS",
      nl: "ANTWERPEN"
    },
    traitement6: {
      fr: "GSM: (+32) 472 78 79 46",
      nl: "GSM: (+32) 472 78 79 46"
    },
    traitement7: {
      fr: "Adresse:<br/>De LevensSpiraal<br/>Grote Beerstraat 52<br/>2018 Antwerpen<br/><a href='mailto:laurence@delevensspiraal.be'>laurence@delevensspiraal.be</a>",
      nl: "Adres:<br/>De LevensSpiraal<br/>Grote Beerstraat 52<br/>2018 Antwerpen<br/><a href='mailto:laurence@delevensspiraal.be'>laurence@delevensspiraal.be</a>"
    },
    traitement8: {
      fr: "Du mardi au vendredi – Horaires variables.<br/>Possibilités le soir et le samedi matin.<br/>Sur RDV – Prendre RDV par téléphone",
      nl: "Consultatie van dinsdag tot vrijdag.<br/>Uitzonderlijk 's avonds en op zaterdagmorgen.<br/>Enkel na telefonische afspraak."
    },
    traitement9: {
      fr: "<b>Accès</b>",
      nl: "<b>PRAKTISCHE INFORMATIE:</b>"
    },
    traitement10: {
      fr: "Près de la Dageraadplaats:",
      nl: "Dicht bij de Dageraadplaats:"
    },
    traitement11: {
      fr: "10 minutes à pieds de la gare d’Antwerpen-Berchem.",
      nl: "Op 10 minuten wandelen van station Antwerpen-Berchem."
    },
    traitement12: {
      fr: "Place de parking à proximité, dans la rue.",
      nl: "Parkeermogelijkheid in de straat."
    },
    traitement14: {
      fr: "Tram ligne 11, depuis la gare d’Antwerpen-Berchem.<br/>Direction Melkmarkt, arrêt Dageraadplaats.",
      nl: "Tram 11, halte Dageraadplaats.<br/><i>(neem vanaf Antwerpen-Berchem richting Melkmarkt, vanuit het centrum richting Berchem Station)</i>"
    },
  }
  return (
    <div style={styles.root}>

      <PhotoBox Foto={Traitement1}>
        <LightBox variant='primary'>
          <h2 dangerouslySetInnerHTML={{__html: text.traitement2[lang]}}/>
        </LightBox>
        <LightBox variant='dark'>
          <div dangerouslySetInnerHTML={{__html: text.traitement3[lang]}}/>
        </LightBox>
        <LightBox variant='white' maxWidth='600px'>
          <div dangerouslySetInnerHTML={{__html: text.traitement4[lang]}}/>
        </LightBox>
        <div style={{paddingBottom: '200px'}} />
      </PhotoBox>

      <PhotoBox Foto={Traitement2}>
        <LightBox variant='primary' align='flex-start'>
          <h2 dangerouslySetInnerHTML={{__html: text.traitement5[lang]}}/>
        </LightBox>
        <LightBox variant='dark' align='flex-start' maxWidth='500px'>
          <ul>
            <li dangerouslySetInnerHTML={{__html: text.traitement6[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement7[lang]}}/>
          </ul>
        </LightBox>
        <LightBox variant='white' align='flex-start'>
          <div dangerouslySetInnerHTML={{__html: text.traitement8[lang]}}/>
        </LightBox>
        <LightBox variant='dark' align='flex-end' maxWidth='500px'>
          <div dangerouslySetInnerHTML={{__html: text.traitement9[lang]}}/>
          <div dangerouslySetInnerHTML={{__html: text.traitement10[lang]}}/>
          <ul>
            <li dangerouslySetInnerHTML={{__html: text.traitement11[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement12[lang]}}/>
            <li dangerouslySetInnerHTML={{__html: text.traitement14[lang]}}/>
          </ul>
        </LightBox>
        <LightBox variant='dark' align='flex-end' maxWidth='500px'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19996.75543833917!2d4.411439731619554!3d51.208124466702444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe29d5ab35cc230cb!2sLaurence%20Tschanz!5e0!3m2!1snl!2sbe!4v1591618620017!5m2!1snl!2sbe"
            width="450" height="400" title="maps"
            frameBorder="0" style={{border:0}}
          />
        </LightBox>
        <div style={{paddingBottom: '50px'}} />
      </PhotoBox>
    </div>
  )
}
export default Content


//////////////////////
const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    textAlign: 'left',
  },
}
